import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContentPageBase from '../../components/ContentPageBase';
import StatusNavBar from '../../components/StatusNavBar';
import { Button, Divider, Dropdown, Form, Stack } from 'rsuite';
import Purchase from '../forecastPages/Purchase';
import Financing from '../forecastPages/Financing';
import AncillaryPurchaseCost from '../forecastPages/AncillaryPurchaseCost';
import Revenues from '../forecastPages/Revenues';
import TaxesAndValueDevelopment from '../forecastPages/TaxesAndValueDevelopment';
import SpecialRepayments from '../forecastPages/SpecialRepayments';
import {
    getForecastData,
    closeHelpDrawer,
    setRequestFinished,
    setRequestMessage
} from '../../reducers/userSlice';
import { useTranslation } from 'react-i18next';
import NavigationFooter from '../../components/NavigationFooter';
import showValidationErrors from '../../utils/showValidationErrors';
import DropdownItem from 'rsuite/esm/Dropdown/DropdownItem';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import Expenses from '../forecastPages/Expenses';
import sendRequest from '../../utils/sendRequest';
import constants from '../../utils/constants';
import ResponsiveStatusNavBar from '../../components/ResponsiveStatusNavBar';

const Forecast = ({ forecastTables }) => {
    const forecastData = useSelector((state) => state.user.forecastData);
    const properties = useSelector((state) => state.user.properties);
    const openedPropertyForecast = useSelector((state) => state.user.openedPropertyForecast);
    const taxSubjects = useSelector((state) => state.user.taxsubjects);

    const [active, setActive] = useState(1);
    const [data, setData] = useState(
        forecastData?.forecastsData ? forecastData?.forecastsData : {}
    );
    const [addCostPages, setAddCostPages] = useState(data?.addCostPages || []);
    const [otherAncillaryCostPages, setOtherAncillaryCostPages] = useState(
        data?.otherAncillaryCostPages || []
    );
    const [specialRepaymentsPages, setSpecialRepaymentsPages] = useState(
        data?.specialRepaymentsPages || []
    );
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const formRef = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (properties.length === 0 || openedPropertyForecast === null)
            navigate(constants.ROUTES.HOME);
        if (
            Array.isArray(forecastData)
                ? forecastData?.filter((forecast) => forecast?.isTest === false)?.length > 0
                : Object.keys(forecastData).length > 0 && forecastData?.isTest === false
        ) {
            setData(forecastData?.forecastsData);
            analytics.trackPage(analyticsConstants.PAGES.EDIT_FORECAST);
        } else analytics.trackPage(analyticsConstants.PAGES.CREATE_FORECAST);
        return () => {
            dispatch(closeHelpDrawer());
        };
    }, []);

    const isSmallBusiness = taxSubjects.filter(
        (item) => item._id === properties?.[openedPropertyForecast]?._owner
    )?.[0]?.taxsubjectsData?.l_small_business_scheme;
    const isBusiness =
        taxSubjects.filter((item) => item._id === properties?.[openedPropertyForecast]?._owner)?.[0]
            ?.taxsubjectsData?.type !== constants.TAX_SUBJECT_TYPES.private;
    const constructionYear =
        properties?.[openedPropertyForecast]?.propertiesData?.l_construction_year;
    const renting_to_privat =
        properties?.[openedPropertyForecast]?.propertiesData?.renting_to_privat;

    const addItem = (setter, target, type) => {
        setter([...target, { id: 'id' + new Date().getTime(), type: type ? type : '' }]);
    };

    const [navbar, setNavbar] = useState([
        { eventKey: 1, text: t('create_forecast.n_purchase'), hasError: false },
        { eventKey: 2, text: t('create_forecast.n_ancillary_purchase_costs'), hasError: false },
        { eventKey: 3, text: t('create_forecast.n_financing'), hasError: false },
        { eventKey: 4, text: t('create_forecast.n_earnings'), hasError: false },
        { eventKey: 5, text: t('create_forecast.n_expenses'), hasError: false },
        { eventKey: 6, text: t('create_forecast.n_taxes_and_value_development'), hasError: false },
        { eventKey: 7, text: t('create_forecast.n_special_repayments'), hasError: false }
    ]);

    //set the first view of the form to be false for all pages
    //after being viewed once, it will be set to true and the form will be checked for errors
    const [firstView, setFirstView] = useState(
        Array(navbar.length).fill(!forecastTables.length > 0)
    );

    //check the form for errors on current page
    useEffect(() => {
        if (!formRef.current) return;
        if (firstView[active - 1]) {
            setFirstView(firstView.map((item, index) => (index === active - 1 ? false : item)));
            return;
        }
        showValidationErrors(formRef.current.root);
        const hasErrors = async () => {
            let hasError = await formRef.current.checkAsync().then((resp) => {
                setNavbar(
                    navbar.map((item, index) =>
                        index === active - 1 ? { ...item, hasError: resp.hasError } : item
                    )
                );
            });
            return hasError;
        };
        hasErrors();
    }, [active]);

    const stepper = async (page) => {
        if (!page) return;
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        let navbarHasErrors = navbar.slice(0, -1).filter((item) => item.hasError).length > 0;
        dispatch(closeHelpDrawer());

        let operation = page ? page : active + 1;
        setNavbar(
            navbar.map((item, index) =>
                index === active - 1 ? { ...item, hasError: hasError } : item
            )
        );

        const analyticsLabel = forecastTables.length > 0 ? 'edit_forecast' : 'create_forecast';
        const activePage =
            forecastTables.length > 0
                ? analyticsConstants.PAGES.EDIT_FORECAST
                : analyticsConstants.PAGES.CREATE_FORECAST;
        if (page)
            analytics.trackSelectNavItem(
                activePage,
                page,
                analyticsConstants.CATEGORIES.FORECASTS,
                hasError
            );
        else
            analytics.trackClick(
                analyticsLabel,
                analyticsConstants.CATEGORIES.FORECASTS,
                active,
                hasError
            );

        if (hasError) {
            operation <= navbar.length && setActive(operation);
            return;
        }

        let savedData = { ...forecastData };
        if (!savedData._owner) {
            savedData._owner = properties[openedPropertyForecast]._id;
        }

        const newData = {
            ...savedData,
            forecastsData: {
                ...data,
                addCostPages,
                otherAncillaryCostPages,
                specialRepaymentsPages
            }
        };

        sendRequest('/updateDB/forecasts', 'post', {
            data: newData,
            IDs: newData._owner
        }).then((resp) => {
            if (resp.success) {
                dispatch(getForecastData(properties[openedPropertyForecast]._id));
                setShowSuccessMessage(true);
                // check if there are any errors in the form
                if (operation === navbar.length + 1) {
                    if (hasError || navbarHasErrors || firstView.includes(true)) {
                        if (firstView.includes(true)) {
                            let newNavbar = [...navbar];
                            firstView.forEach((item, itemIndex) => {
                                if (item === true) {
                                    newNavbar = newNavbar.map((navItem, index) =>
                                        index === itemIndex
                                            ? { ...navItem, hasError: true }
                                            : navItem
                                    );
                                }
                            });
                            setNavbar(newNavbar);
                        }
                        dispatch(setRequestFinished(true));
                        dispatch(
                            setRequestMessage({
                                type: 'error',
                                message: t('general.e_generic_form_error')
                            })
                        );
                        return;
                    }

                    if (navbar.filter((item) => item.hasError).length > 0) {
                        setNavbar(navbar);
                        dispatch(setRequestFinished(true));
                        dispatch(
                            setRequestMessage({
                                type: 'error',
                                message: t('general.e_generic_form_error')
                            })
                        );
                        return;
                    }

                    dispatch(
                        setRequestMessage({
                            type: 'success',
                            message: t('create_forecast.l_forecast_updated')
                        })
                    );
                    dispatch(setRequestFinished(true));
                    navigate(constants.ROUTES.FORECAST_CALCULATION.HOME);
                }
            } else {
                setNavbar(
                    navbar.map((item, index) =>
                        index === active - 1 ? { ...item, hasError: true } : item
                    )
                );
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('global.e_server_error')
                    })
                );
            }
        });

        if (operation <= navbar.length) setActive(operation);
    };

    return (
        <ContentPageBase
            header={navbar[active - 1].text}
            className={'forecast-page'}
            leftItem={
                window.innerWidth > 850 ? (
                    <StatusNavBar
                        appearance="subtle"
                        active={active}
                        onSelect={stepper}
                        titles={navbar}
                    />
                ) : null
            }
            topItem={
                window.innerWidth <= 850 ? (
                    <ResponsiveStatusNavBar
                        appearance="tabs"
                        active={active}
                        onSelect={stepper}
                        titles={navbar}
                    />
                ) : null
            }
            footer={
                <NavigationFooter
                    firstFunction={() => {
                        if (showSuccessMessage) {
                            dispatch(setRequestFinished(true));
                            dispatch(
                                setRequestMessage({
                                    type: 'success',
                                    message: t('create_forecast.l_forecast_updated')
                                })
                            );
                        }
                        navigate(-1);
                        const analyticsLabel = data
                            ? 'cancel_edit_forecast'
                            : 'cancel_create_forecast';
                        analytics.trackClick(
                            analyticsLabel,
                            analyticsConstants.CATEGORIES.FORECASTS,
                            active
                        );
                    }}
                    secondFunction={() => stepper(active + 1)}
                    firstLabel={showSuccessMessage ? 'Speichern und verlassen' : t('global.cancel')}
                    secondLabel={
                        active !== navbar.length
                            ? t('global.next')
                            : t('global.forecast_calculation')
                    }
                    isSubmitButton={
                        active === navbar.length &&
                        navbar.slice(0, -1).filter((item) => item.hasError).length === 0
                    }
                />
            }>
            <Form
                ref={formRef}
                onChange={(object) => {
                    const diffKeys = Object.keys(object)
                        .reduce((acc, key) => {
                            if (!(key in data) || data[key] !== object[key]) {
                                acc.push(key);
                            }
                            return acc;
                        }, [])
                        .filter(
                            (item) =>
                                ![
                                    'financing_amount',
                                    'financing_amount_percent',
                                    'l_land_register_registration_fee',
                                    'l_land_register_registration_fee_percent',
                                    'l_real_estate_transfer_tax',
                                    'l_real_estate_transfer_tax_percent',
                                    'l_real_estate_broker_fee',
                                    'l_real_estate_broker_fee_percent',
                                    'l_trust_and_contract_establishment_fee',
                                    'l_trust_and_contract_establishment_fee_percent',
                                    'l_purchase_price',
                                    'l_loan_down_payment',
                                    'l_total_monthly_rent'
                                ].includes(item)
                        );
                    if (diffKeys.length > 0)
                        setData((prev) => ({
                            ...prev,
                            ...object
                        }));
                }}
                formValue={data}
                checkTrigger="none"
                className="clearfix">
                {active === 1 && (
                    <Purchase
                        data={data}
                        setData={setData}
                        pages={addCostPages}
                        setPages={setAddCostPages}>
                        {addCostPages.length < 4 && (
                            <>
                                <Divider />
                                <Stack justifyContent="flex-end">
                                    <Dropdown
                                        placement="bottomStart"
                                        renderToggle={(props, ref) => (
                                            <Button {...props} ref={ref}>
                                                + {t('create_forecast.l_add_cost_item')}
                                            </Button>
                                        )}>
                                        {addCostPages.filter((item) => item.type === 'kitchen')
                                            .length === 0 && (
                                            <DropdownItem
                                                onClick={() =>
                                                    addItem(
                                                        setAddCostPages,
                                                        addCostPages,
                                                        'kitchen'
                                                    )
                                                }>
                                                {t('create_forecast.l_kitchen')}
                                            </DropdownItem>
                                        )}
                                        {addCostPages.filter((item) => item.type === 'garage')
                                            .length === 0 && (
                                            <DropdownItem
                                                onClick={() =>
                                                    addItem(setAddCostPages, addCostPages, 'garage')
                                                }>
                                                {t('create_forecast.l_garage')}
                                            </DropdownItem>
                                        )}
                                        {addCostPages.filter((item) => item.type === 'misc')
                                            .length === 0 && (
                                            <DropdownItem
                                                onClick={() =>
                                                    addItem(setAddCostPages, addCostPages, 'misc')
                                                }>
                                                {t('create_forecast.l_other')}
                                            </DropdownItem>
                                        )}
                                        {addCostPages.filter((item) => item.type === 'renovation')
                                            .length === 0 && (
                                            <DropdownItem
                                                onClick={() =>
                                                    addItem(
                                                        setAddCostPages,
                                                        addCostPages,
                                                        'renovation'
                                                    )
                                                }>
                                                {t('create_forecast.l_renovation')}
                                            </DropdownItem>
                                        )}
                                    </Dropdown>
                                </Stack>
                            </>
                        )}
                    </Purchase>
                )}
                {active === 3 && <Financing data={data} setData={setData} />}
                {active === 2 && (
                    <AncillaryPurchaseCost
                        data={data}
                        setData={setData}
                        pages={otherAncillaryCostPages}
                        addCostPages={addCostPages}
                        setPages={setOtherAncillaryCostPages}
                        isSmallBusiness={isSmallBusiness}>
                        {otherAncillaryCostPages.length < 3 && (
                            <>
                                <Divider />
                                <Stack justifyContent="flex-end">
                                    <Button
                                        onClick={() =>
                                            addItem(
                                                setOtherAncillaryCostPages,
                                                otherAncillaryCostPages
                                            )
                                        }>
                                        +
                                        {' ' +
                                            t(
                                                'create_forecast.l_add_other_ancillary_purchase_costs'
                                            )}
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </AncillaryPurchaseCost>
                )}
                {active === 4 && (
                    <Revenues addCostPages={addCostPages} data={data} setData={setData} />
                )}
                {active === 5 && <Expenses data={data} isSmallBusiness={isSmallBusiness} />}
                {active === 6 && (
                    <TaxesAndValueDevelopment
                        addCostPages={addCostPages}
                        data={data}
                        setData={setData}
                        constructionYear={constructionYear}
                        isBusiness={isBusiness}
                        renting_to_privat={renting_to_privat}
                    />
                )}
                {active === 7 && (
                    <SpecialRepayments
                        data={data}
                        setData={setData}
                        pages={specialRepaymentsPages}
                        setPages={setSpecialRepaymentsPages}>
                        <Stack justifyContent="flex-end">
                            <Button
                                onClick={() =>
                                    addItem(setSpecialRepaymentsPages, specialRepaymentsPages)
                                }>
                                + {t('create_forecast.l_add_special_repayment')}
                            </Button>
                        </Stack>
                    </SpecialRepayments>
                )}
            </Form>
        </ContentPageBase>
    );
};

export default Forecast;
