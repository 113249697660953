import React from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { Divider, Stack, IconButton } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { SchemaModel, StringType, NumberType, MixedType } from 'schema-typed';
import LabeledTextarea from '../../components/LabeledTextarea';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import DatePickerInput from '../../components/DatePickerInput';

const Sondertilgung = ({ data, setData, pages, setPages, children }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).specialRepayments.objectTypeSchemaSpec;
    //dynamic schema models
    const models = [];
    pages.forEach(() => {
        const model = SchemaModel({
            l_prepayment_date: MixedType().isRequired('This field is required'),
            l_prepayment_value: NumberType()
                .min(0, t('global.e_positive_number'))
                .isRequired('This field is required'),
            l_prepayment_origin: StringType().isRequired('This field is required')
        });
        models.push(model);
    });

    const deletePage = (id) => {
        setPages(pages.filter((item) => item.id !== id));
    };
    return (
        <>
            <LabeledTextarea
                label="Kommentar für das Finanzamt"
                name="l_comment"
                autoFocus
                value={data?.l_comment}
                onChange={(value) => setData({ ...data, l_comment: value })}
            />
            <LabeledInputWithSymbol
                label="Link zu relevanten Dokumenten"
                type="link"
                alignLeft
                name="l_link_to_documents"
                rule={validationSchemas.l_link_to_documents}
            />
            <Divider />

            {pages.map((page, index) => (
                <React.Fragment key={index}>
                    <Stack className="additional-cost-item">
                        <h3 className="page-subtitle">
                            {t('create_forecast.h_special_repayment')}
                        </h3>
                        <IconButton
                            icon={<FontAwesomeIcon className="rs-icon" icon={faTrash} />}
                            onClick={() => deletePage(page.id)}
                        />
                    </Stack>
                    <DatePickerInput
                        label={t('create_forecast.l_special_repayment_date')}
                        type="date"
                        name={'l_prepayment_date_' + page.id}
                        value={data?.['l_prepayment_date_' + page.id]}
                        onChange={(value) => {
                            setData({ ...data, ['l_prepayment_date_' + page.id]: value });
                        }}
                        rule={
                            models[index]?.spec?.l_prepayment_date ||
                            models[index]?.$spec?.l_prepayment_date
                        }
                        infoText={t('create_forecast.i_special_repayment_date')}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_special_repayment_value')}
                        type="number"
                        infoText={t('create_forecast.i_special_repayment_value')}
                        symbolIcon="€"
                        name={'l_prepayment_value_' + page.id}
                        rule={
                            models[index]?.spec?.l_prepayment_value ||
                            models[index]?.$spec?.l_prepayment_value
                        }
                    />
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_special_repayment_origin')}
                        type="text"
                        name={'l_prepayment_origin_' + page.id}
                        infoText={t('create_forecast.i_special_repayment_origin')}
                        rule={
                            models[index]?.spec?.l_prepayment_origin ||
                            models[index]?.$spec?.l_prepayment_origin
                        }
                    />
                    <Divider />
                </React.Fragment>
            ))}
            {children}
        </>
    );
};

export default Sondertilgung;
