import React, { useRef, useState, useEffect } from 'react';
import ContentPageBase from '../../components/ContentPageBase';
import StatusNavBar from '../../components/StatusNavBar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BasicPropertyInformation from '../createPropertyPages/BasicPropertyInformation';
import { Form } from 'rsuite';
import DetailedInformation from '../createPropertyPages/DetailedInformation';
import NavigationFooter from '../../components/NavigationFooter';
import { useSelector, useDispatch } from 'react-redux';
import showValidationErrors from '../../utils/showValidationErrors';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import {
    setProperties,
    setRequestFinished,
    setRequestMessage,
    closeHelpDrawer,
    setCurrentProperty
} from '../../reducers/userSlice';
import AreaInformation from '../createPropertyPages/AreaInformation';
import constants from '../../utils/constants';
import ResponsiveStatusNavBar from '../../components/ResponsiveStatusNavBar';

const CreateOrEditProperty = ({ editID, ownerID, taxSubjectsIDs }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const currentTaxsubject = useSelector((state) => state.user.currentTaxsubject);
    const properties = useSelector((state) => state.user.properties);
    const requestFinished = useSelector((state) => state.user.requestFinished);
    const requestMessage = useSelector((state) => state.user.requestMessage);

    const [active, setActive] = useState(1);

    const [navbar, setNavbar] = useState([
        { eventKey: 1, text: t('create_property.n_basic_info'), hasError: false },
        { eventKey: 2, text: t('create_property.n_detailed_info'), hasError: false },
        { eventKey: 3, text: 'Flächenangaben', hasError: false }
    ]);

    //set the first view of the form to be false for all pages
    //after being viewed once, it will be set to true and the form will be checked for errors
    const [firstView, setFirstView] = useState([false, ...Array(navbar.length - 1).fill(!editID)]);

    const [data, setData] = useState({ _owner: ownerID || currentTaxsubject?._id });

    useEffect(() => {
        if (editID) analytics.trackPage(analyticsConstants.PAGES.EDIT_PROPERTY);
        else analytics.trackPage(analyticsConstants.PAGES.CREATE_PROPERTY);
        return () => {
            dispatch(closeHelpDrawer());
        };
    }, []);

    useEffect(() => {
        if (requestFinished && requestMessage?.type !== 'error') {
            dispatch(setCurrentProperty(properties[properties.length - 1]));
            if (editID) {
                dispatch(setCurrentProperty(properties.find((doc) => doc._id === editID)));
                navigate(-1);
                return;
            }
            dispatch(setCurrentProperty(properties[properties.length - 1]));
            navigate(constants.ROUTES.PROPERTIES.DASHBOARD);
        }
    }, [requestFinished]);

    useEffect(() => {
        if (editID) {
            const property = properties.find((doc) => doc._id === editID);
            property && setData({ ...data, ...property.propertiesData });
        }
        setLoaded(true);
    }, [editID, properties]);

    //check the form for errors on current page
    useEffect(() => {
        if (!formRef.current) return;
        if (firstView[active - 1]) {
            setFirstView(firstView.map((item, index) => (index === active - 1 ? false : item)));
            return;
        }
        showValidationErrors(formRef.current.root);
        formRef.current.check();
    }, [active]);

    const jumpToPage = async (page) => {
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        const activePage = editID
            ? analyticsConstants.PAGES.EDIT_PROPERTY
            : analyticsConstants.PAGES.CREATE_PROPERTY;
        analytics.trackSelectNavItem(
            activePage,
            page,
            analyticsConstants.CATEGORIES.PROPERTIES,
            hasError
        );
        setNavbar(
            navbar.map((item, index) =>
                index === active - 1 ? { ...item, hasError: hasError } : item
            )
        );
        setActive(page);
        dispatch(closeHelpDrawer());
    };

    const handleCancel = () => {
        const analyticsLabel = editID ? 'cancel_edit_property' : 'cancel_create_property';
        analytics.trackClick(analyticsLabel, analyticsConstants.CATEGORIES.PROPERTIES, active);
        navigate(-1);
    };

    const stepper = async () => {
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        let navbarHasErrors = navbar.slice(0, -1).filter((item) => item.hasError).length > 0;
        const analyticsLabel = editID ? 'edit_property' : 'create_property';
        analytics.trackClick(
            analyticsLabel,
            analyticsConstants.CATEGORIES.PROPERTIES,
            active,
            hasError || navbarHasErrors
        );
        dispatch(closeHelpDrawer());
        setNavbar(
            navbar.map((item, index) =>
                index === active - 1 ? { ...item, hasError: hasError } : item
            )
        );
        if (active === navbar.length) {
            if (hasError || navbarHasErrors || firstView.includes(true)) {
                if (firstView.includes(true)) {
                    let newNavbar = [...navbar];
                    firstView.forEach((item, itemIndex) => {
                        if (item === true) {
                            newNavbar = newNavbar.map((navItem, index) =>
                                index === itemIndex ? { ...navItem, hasError: true } : navItem
                            );
                        }
                    });
                    setNavbar(newNavbar);
                }
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('general.e_generic_form_error')
                    })
                );
                return;
            }
            let propertyToSave = editID
                ? {
                      _id: editID,
                      _owner: currentTaxsubject?._id,
                      propertiesData: data
                  }
                : {
                      _owner: currentTaxsubject?._id,
                      propertiesData: data
                  };
            dispatch(setProperties({ data: propertyToSave, IDs: taxSubjectsIDs }));
            return;
        }
        setActive(active + 1);
    };

    return (
        <>
            <ContentPageBase
                header={
                    editID
                        ? t('create_property.h_edit_property')
                        : t('create_property.h_create_new_property')
                }
                leftItem={
                    window.innerWidth > 850 ? (
                        <StatusNavBar
                            appearance="subtle"
                            titles={navbar}
                            active={active}
                            onSelect={jumpToPage}
                        />
                    ) : null
                }
                topItem={
                    window.innerWidth <= 850 ? (
                        <ResponsiveStatusNavBar
                            appearance="tabs"
                            horizontal
                            titles={navbar}
                            active={active}
                            onSelect={jumpToPage}
                        />
                    ) : null
                }
                footer={
                    <NavigationFooter
                        firstFunction={handleCancel}
                        secondFunction={stepper}
                        firstLabel={t('global.cancel')}
                        secondLabel={
                            active !== navbar.length ? t('global.next') : t('global.submit')
                        }
                        isSubmitButton={
                            active === navbar.length &&
                            navbar.slice(0, -1).filter((item) => item.hasError).length === 0
                        }
                    />
                }>
                {loaded && (
                    <Form
                        ref={formRef}
                        onChange={(object) => setData({ ...data, ...object })}
                        formValue={data}
                        checkTrigger="none">
                        {active === 1 && (
                            <BasicPropertyInformation
                                ownerId={ownerID || currentTaxsubject?._id}
                                data={data}
                                isEdit={editID}
                                setData={setData}
                            />
                        )}
                        {active === 2 && <DetailedInformation data={data} setData={setData} />}
                        {active === 3 && <AreaInformation data={data} />}
                    </Form>
                )}
            </ContentPageBase>
        </>
    );
};

export default CreateOrEditProperty;
