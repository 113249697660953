import React, { useState, useEffect } from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import DropdownInput from '../../components/DropdownInput';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';
import ToggleInput from '../../components/ToggleInput';
import constants from '../../utils/constants';
import pricePerM2 from '../../utils/pricePerM2AT.json';

const DetailedInformation = ({ data, setData }) => {
    const { t } = useTranslation();

    const validationSchema = getValidationSchemas(t).detailedInormation.objectTypeSchemaSpec;
    const [showRenovationInput, setShowRenovationInput] = useState(
        data.show_last_general_renovation
    );
    const rentalTypes = Object.values(constants.RENTAL_TYPES).map((type) => ({
        label: t(type),
        value: type
    }));

    useEffect(() => {
        let localData = data;
        if (!data?.l_number_of_garages) localData = { ...localData, l_number_of_garages: 0 };
        if (!data?.l_type_of_rental && data.l_property_type !== constants.PROPERTY_TYPES.GARAGE)
            localData = {
                ...localData,
                l_type_of_rental:
                    rentalTypes[
                        data.l_property_type === constants.PROPERTY_TYPES.APARTMENT_BUILDING ? 1 : 0
                    ].value
            };
        setData(localData);

        let cityName = data?.l_city === 'Wien' ? 'Vienna' : data?.l_city;
        const where = encodeURIComponent(
            JSON.stringify({
                name: cityName
            })
        );
        let isSmallCity = true;
        let highPricePerM2 = false;
        fetch(
            `https://parseapi.back4app.com/classes/Austriancities_City?limit=10&order=name&keys=name,location,population&where=${where}`,
            {
                headers: {
                    'X-Parse-Application-Id': 'Zm9T8M0XFqZfPn5mAkEwGDMXepROcwEkmHsTi9oA', // This is your app's application id
                    'X-Parse-REST-API-Key': '0Saggx3yEoveiOJp1q1UT1ShZuBIIXrYS93dVQwY' // This is your app's REST API key
                }
            }
        )
            .then((resp) => resp.json())
            .then((result) => {
                if (result?.results?.[0]?.population > 100000) isSmallCity = false;
                const price = pricePerM2.find(
                    (item) => item.GemName === data?.l_city || item.GemName.includes(data?.l_city)
                );
                if (price && price.BPreis1516 > 400) highPricePerM2 = true;
                if (!data?.l_share_of_land)
                    setData((prev) => {
                        return {
                            ...prev,
                            l_share_of_land:
                                isSmallCity && !highPricePerM2
                                    ? 20
                                    : data?.l_is_more_than_ten_units
                                      ? 30
                                      : 40
                        };
                    });
            });
    }, []);

    useEffect(() => {
        if (data?.l_share_of_land === 40 && data?.l_is_more_than_ten_units)
            setData((prev) => {
                return { ...prev, l_share_of_land: 30 };
            });
        else if (data?.l_share_of_land === 30 && !data?.l_is_more_than_ten_units)
            setData((prev) => {
                return { ...prev, l_share_of_land: 40 };
            });
    }, [data?.l_is_more_than_ten_units]);

    return (
        <>
            <LabeledInputWithSymbol
                label={t('create_property.l_construction_year')}
                type="number"
                formatNumeric={false}
                autoFocus
                name="l_construction_year"
                infoText={t('create_property.i_construction_year')}
                symbolIcon="Jahr"
                rule={validationSchema.l_construction_year}
            />
            <ToggleInput
                name="show_last_general_renovation"
                defaultChecked={data.show_last_general_renovation}
                label={t('create_property.l_general_renovation')}
                onToggle={(state) => {
                    setData({
                        ...data,
                        show_last_general_renovation: state,
                        last_general_renovation: state ? data.last_general_renovation : null
                    });
                    setShowRenovationInput(state);
                }}
            />
            {showRenovationInput && (
                <LabeledInputWithSymbol
                    label={t('create_property.l_last_general_renovation')}
                    type="number"
                    formatNumeric={false}
                    name="last_general_renovation"
                    symbolIcon="Jahr"
                    isIndentedInput
                    rule={validationSchema.last_renovation_year}
                />
            )}
            <LabeledInputWithSymbol
                label={t('create_property.l_number_of_garages')}
                type="number"
                formatNumeric={false}
                name="l_number_of_garages"
                infoText={t('create_property.i_number_of_garages')}
                rule={validationSchema.l_number_of_garages}
            />
            <ToggleInput
                name="l_is_more_than_ten_units"
                defaultChecked={data.l_is_more_than_ten_units}
                label={t('create_property.l_building_with_10plus_flats')}
                onToggle={(state) => setData({ ...data, l_is_more_than_ten_units: state })}
            />
            <LabeledInputWithSymbol
                label={t('create_property.l_share_of_land')}
                type="number"
                name="l_share_of_land"
                infoText={t('create_property.i_share_of_land')}
                symbolIcon="%"
                rule={validationSchema.l_share_of_land}
            />
            {data?.l_property_type !== constants.PROPERTY_TYPES.GARAGE && (
                <ToggleInput
                    name="l_elevator_available"
                    defaultChecked={data.l_elevator_available}
                    label={t('create_property.l_elevator')}
                    onToggle={(state) => setData({ ...data, l_elevator_available: state })}
                />
            )}
            {data?.l_property_type !== constants.PROPERTY_TYPES.APARTMENT_BUILDING &&
                data?.l_property_type !== constants.PROPERTY_TYPES.GARAGE && (
                    <LabeledInputWithSymbol
                        label={t('create_property.l_floor')}
                        type="number"
                        name="l_floor_number"
                        rule={validationSchema.l_floor_number}
                    />
                )}
            <DropdownInput
                dropdownOption={rentalTypes}
                dropdownOptionReady
                label={t('create_forecast.l_type_of_rental')}
                name="l_type_of_rental"
                infoText={t('create_forecast.i_type_of_rental')}
                alignRight
                value={data?.l_type_of_rental}
                rule={validationSchema.l_type_of_rental}
            />
        </>
    );
};

export default DetailedInformation;
