import React, { useEffect, useState } from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import DoubleFieldedInput from '../../components/DoubleFieldedInput';
import { Divider, Stack } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { openHelpDrawer } from '../../reducers/userSlice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../components/NumericFormatter';

const Revenue = ({ data, setData, year, forecastData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const validationSchemas = getValidationSchemas(t).revenue.objectTypeSchemaSpec;
    const [fullValue, setFullValue] = useState(0);
    const [basePercent, setBasePercent] = useState(0);

    const pageInfoText = () => ({
        title: t('create_historic_forecast_data.h_historic_forecast_data').replace(
            '{{year}}',
            year
        ),
        message: t('create_historic_forecast_data.i_historic_forecast_data')
    });

    useEffect(() => {
        if (data?.l_monthly_target_rent && data?.l_yearly_rent) {
            setFullValue(data.l_monthly_target_rent * forecastData.num_rent_active);
            let calculatedPercent =
                ((data.l_yearly_rent - data.l_monthly_target_rent * forecastData.num_rent_active) *
                    100) /
                (data.l_monthly_target_rent * forecastData.num_rent_active);
            setBasePercent(calculatedPercent > 0 ? 0 : calculatedPercent);
        } else {
            if (fullValue !== 0 && basePercent !== 0) {
                setFullValue(0);
                setBasePercent(0);
            }
        }
    }, [data?.l_monthly_target_rent, data?.l_yearly_rent, forecastData]);

    return (
        <>
            <Stack className="revenue-heading">
                <Stack.Item>
                    <h4>
                        {t('create_historic_forecast_data.h_historic_forecast_data').replace(
                            '{{year}}',
                            year
                        )}
                    </h4>
                </Stack.Item>
                <Stack.Item>
                    <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="info-icon"
                        onClick={() => dispatch(openHelpDrawer(pageInfoText()))}
                    />
                </Stack.Item>
            </Stack>
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_yearly_rent')}
                type="number"
                name="l_yearly_rent"
                autoFocus
                infoText={t('create_historic_forecast_data.i_yearly_rent')}
                symbolIcon="€"
                rule={validationSchemas.l_yearly_rent}
            />
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_monthly_target_rent')}
                type="number"
                name="l_monthly_target_rent"
                symbolIcon="€"
                rule={validationSchemas.l_monthly_target_rent}
            />
            <DoubleFieldedInput
                label={t('create_historic_forecast_data.l_calculated_vacancy')}
                name="l_calculated_vacancy"
                fullValue={fullValue}
                disabled
                basePercent={basePercent}
                onChange={(item1, item2) => {
                    setData({
                        ...data,
                        l_calculated_vacancy: item1,
                        l_calculated_vacancy_percent: item2
                    });
                }}
            />
            <Divider />
            <h4>{t('create_historic_forecast_data.h_change_forecast_parameters')}</h4>
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_monthly_rent')}
                type="number"
                name="l_expected_monthly_rent"
                symbolIcon="€"
                rule={validationSchemas.l_monthly_rent_historical}
                helpText={t('create_historic_forecast_data.l_diff_to_historic_value').replace(
                    '{{value}}',
                    forecastData &&
                        data?.l_expected_monthly_rent &&
                        forecastData.num_rent_active !== 0
                        ? formatNumber(
                              +(
                                  forecastData.yearly_rent / forecastData.num_rent_active -
                                  data.l_expected_monthly_rent
                              )
                          )
                        : '-'
                )}
            />
            {data?.l_expected_monthly_rent_kitchen !== undefined &&
                data?.l_expected_monthly_rent_kitchen !== 0 && (
                    <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_expected_monthly_rent_kitchen')}
                        type="number"
                        name="l_expected_monthly_rent_kitchen"
                        symbolIcon="€"
                        helpText={t(
                            'create_historic_forecast_data.l_diff_to_historic_value'
                        ).replace(
                            '{{value}}',
                            forecastData &&
                                data?.l_expected_monthly_rent_kitchen &&
                                forecastData.num_rent_active !== 0
                                ? formatNumber(
                                      +(
                                          forecastData.yearly_rent_kitchen /
                                              forecastData.num_rent_active -
                                          data.l_expected_monthly_rent_kitchen
                                      )
                                  )
                                : '-'
                        )}
                    />
                )}
            {data?.l_expected_monthly_rent_garage !== undefined &&
                data?.l_expected_monthly_rent_garage !== 0 && (
                    <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_expected_monthly_rent_garage')}
                        type="number"
                        name="l_expected_monthly_rent_garage"
                        symbolIcon="€"
                        helpText={t(
                            'create_historic_forecast_data.l_diff_to_historic_value'
                        ).replace(
                            '{{value}}',
                            forecastData &&
                                data?.l_expected_monthly_rent_garage &&
                                forecastData.num_rent_active !== 0
                                ? formatNumber(
                                      +(
                                          forecastData.yearly_rent_garage /
                                              forecastData.num_rent_active -
                                          data.l_expected_monthly_rent_garage
                                      )
                                  )
                                : '-'
                        )}
                    />
                )}
            {data?.l_expected_monthly_rent_misc !== undefined &&
                data?.l_expected_monthly_rent_misc !== 0 && (
                    <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_expected_monthly_rent_misc')}
                        type="number"
                        name="l_expected_monthly_rent_misc"
                        symbolIcon="€"
                        helpText={t(
                            'create_historic_forecast_data.l_diff_to_historic_value'
                        ).replace(
                            '{{value}}',
                            forecastData &&
                                data?.l_expected_monthly_rent_misc &&
                                forecastData.num_rent_active !== 0
                                ? formatNumber(
                                      +(
                                          forecastData.yearly_rent_misc /
                                              forecastData.num_rent_active -
                                          data.l_expected_monthly_rent_misc
                                      )
                                  )
                                : '-'
                        )}
                    />
                )}
            <LabeledInputWithSymbol
                label={t('create_forecast.l_rent_increase')}
                type="number"
                name={t('create_forecast.i_rent_increase')}
                symbolIcon="%"
                rule={validationSchemas.l_rent_increase_historical}
                helpText={t('create_historic_forecast_data.l_diff_to_historic_value').replace(
                    '{{value}}',
                    forecastData && data?.l_rent_increase
                        ? formatNumber(+(forecastData.l_rent_increase - data.l_rent_increase))
                        : '-'
                )}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_vacancy_rate')}
                type="number"
                name="l_vacancy_rate"
                infoText={t('create_forecast.i_vacancy_rate')}
                symbolIcon="%"
                rule={validationSchemas.l_vacancy_rate_historical}
                helpText={t('create_historic_forecast_data.l_diff_to_historic_value').replace(
                    '{{value}}',
                    forecastData && data?.l_vacancy_rate
                        ? formatNumber(+(forecastData.l_vacancy_rate - data.l_vacancy_rate))
                        : '-'
                )}
            />
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_calculated_yearly_rent')}
                type="number"
                name="l_calculated_yearly_rent"
                disabled
                defaultValue={forecastData ? data?.l_expected_monthly_rent * 12 : 0}
                symbolIcon="€"
            />
        </>
    );
};

export default Revenue;
