import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stack, Placeholder, SelectPicker, Panel } from 'rsuite';
import {
    setCurrentTaxsubject,
    setCurrentProperties,
    setOpenedPropertyForecast,
    getProperties,
    getTaxsubjects
} from '../../reducers/userSlice';
import { useTranslation } from 'react-i18next';
import ContentPageBase from '../../components/ContentPageBase';
import ClickInput from '../../components/ClickInput';
import sendRequest from '../../utils/sendRequest';
import KpiItem from '../../components/KpiItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faUser } from '@fortawesome/pro-solid-svg-icons';
import constants from '../../utils/constants';
import CreateInvitationModal from '../../components/CreateInvitationModal';
import TaxSubjectDashboardSection from '../../components/TaxSubjectDashboardSection';
import getTaxsubjectName from '../../utils/getTaxsubjectName';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import taxsubjectBg from '../../img/tax-subject-bg.jpg';
import PromotionCard from '../../components/PromotionCard';
import yearlySumUp from '../../utils/yearlySumUp';
import getUTCDate from '../../utils/getUTCDate';

const TaxSubjectDashboard = ({ editTaxSubject }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const taxsubjects = useSelector((state) => state.user.taxsubjects);
    const currentTaxsubject = useSelector((state) => state.user.currentTaxsubject);
    const properties = useSelector((state) => state.user.properties);
    const currentProperties = useSelector((state) => state.user.currentProperties);
    const taxsubjectsLoading = useSelector((state) => state.user.taxsubjectsLoading);

    const [taxSubjectName, setTaxSubjectName] = useState('');
    const [taxSubjectsDropdownOptions, setTaxSubjectsDropdownOptions] = useState([]);
    const [forecastDatas, setForecastDatas] = useState(null);
    const [propertiesForecastCalculation, setPropertiesForecastCalculation] = useState(null);
    const [numberOfPropertiesData, setNumberOfPropertiesData] = useState([undefined, '-']);
    const [propertyTypesText, setPropertyTypesText] = useState(null);
    const [remainigLoanData, setRemainigLoanData] = useState([undefined, '-']);
    const [fullExpectedRentData, setFullExpectedRentData] = useState([undefined, '-']);
    const [netProfitData, setNetProfitData] = useState([undefined, '-']);
    const [brutoRentalYieldData, setBrutoRentalYieldData] = useState([undefined, '-']);
    const [expenseIncomeBeforeTaxData, setExpenseIncomeBeforeTaxData] = useState([undefined, '-']);
    const [planedNetRentalYieldData, setPlanedNetRentalYieldData] = useState([undefined, '-']);
    const [rentPerSquareMeterData, setRentPerSquareMeterData] = useState([undefined, '-']);
    const [hobbieProperties, setHobbieProperties] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const [invitationModalOpen, setInvitationModalOpen] = useState(false);

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.TAX_SUBJECT_DASHBOARD);
    }, []);

    useEffect(() => {
        if (taxsubjectsLoading) return;
        if (!currentTaxsubject) {
            if (taxsubjects && taxsubjects.length > 0) {
                dispatch(setCurrentTaxsubject(taxsubjects[0]));
            } else {
                dispatch(getTaxsubjects());
            }
        }
    }, [taxsubjectsLoading]);

    useEffect(() => {
        if (!currentTaxsubject) return;
        dispatch(getProperties(currentTaxsubject._id));
    }, [currentTaxsubject]);

    useEffect(() => {
        if (!currentTaxsubject || !properties) return;
        const currentPropertiesList = properties.filter(
            (property) => property._owner === currentTaxsubject._id
        );
        dispatch(setCurrentProperties(currentPropertiesList));
        setTaxSubjectName(getTaxsubjectName(currentTaxsubject));
    }, [currentTaxsubject, properties]);

    useEffect(() => {
        if (!taxsubjects) return;
        if (!currentTaxsubject) {
            dispatch(setCurrentTaxsubject(taxsubjects[0]));
        }
        const taxSubjectsDropdownOptions = [];
        taxsubjects.forEach((taxSubject) => {
            taxSubjectsDropdownOptions.push({
                label: getTaxsubjectName(taxSubject),
                value: taxSubject?._id
            });
        });
        setTaxSubjectsDropdownOptions(taxSubjectsDropdownOptions);
    }, [taxsubjects, setTaxSubjectsDropdownOptions]);

    // reset data when taxsubject changes -> currentProperties changes
    useEffect(() => {
        setDataFetched(false);
        if (!currentProperties || currentProperties.length === 0) {
            setNumberOfPropertiesData([undefined, '-']);
            setPropertyTypesText('No properties');
            setRemainigLoanData([undefined, '-']);
            setFullExpectedRentData([undefined, '-']);
            setNetProfitData([undefined, '-']);
            setBrutoRentalYieldData([undefined, '-']);
            setExpenseIncomeBeforeTaxData([undefined, '-']);
            setRentPerSquareMeterData([undefined, '-']);
            setPlanedNetRentalYieldData([undefined, '-']);
            setDataFetched(true);
            return;
        }
        setPropertiesForecastCalculation(null);
        setForecastDatas(null);
        setNumberOfPropertiesData([undefined, '-']);
        setPropertyTypesText('No properties');
        setRemainigLoanData([undefined, '-']);
        setExpenseIncomeBeforeTaxData([undefined, '-']);
        setFullExpectedRentData([undefined, '-']);
        setNetProfitData([undefined, '-']);
        setBrutoRentalYieldData([undefined, '-']);
        setRentPerSquareMeterData([undefined, '-']);
        setPlanedNetRentalYieldData([undefined, '-']);
        setHobbieProperties(null);

        //get data for first kpiItem
        const propertyTypes = {};
        let propertyTypesText = '';

        currentProperties.forEach((property) => {
            if (property.propertiesData) {
                //get property types
                let type = property.propertiesData.l_property_type;
                // fallback for older properties
                if (type === 'Wohnung') type = constants.PROPERTY_TYPES.APARTMENT;
                if (type === 'Zinshaus') type = constants.PROPERTY_TYPES.APARTMENT_BUILDING;
                if (type === 'Garage') type = constants.PROPERTY_TYPES.GARAGE;
                if (propertyTypes[type]) {
                    propertyTypes[type] += 1;
                } else {
                    propertyTypes[type] = 1;
                }
            }
        });

        const keys = Object.keys(propertyTypes);
        keys.forEach((type, index) => {
            propertyTypesText += t(type) + ' ' + propertyTypes[type];
            if (index !== keys.length - 1) {
                propertyTypesText += ', ';
            }
        });
        setPropertyTypesText(propertyTypesText);

        // get forecast data & calculations
        const forecastCalculationPromises = [];
        const forecasDatasPromises = [];
        currentProperties.forEach((property) => {
            forecastCalculationPromises.push(
                sendRequest('/forecastCalculations', 'post', {
                    ID: property._id,
                    isSmallBusiness: currentTaxsubject?.taxsubjectsData?.l_small_business_scheme,
                    tax_rate: currentTaxsubject?.taxsubjectsData.tax_progression,
                    preset: constants.CALCULATION_PRESETS.FORECAST
                }).then((resp) => ({ ...resp, propertyId: property._id }))
            );
            forecasDatasPromises.push(
                sendRequest('/queryDB/forecasts', 'post', { IDs: property._id }).then(
                    (resp) => resp
                )
            );
        });
        Promise.all([...forecastCalculationPromises, ...forecasDatasPromises]).then((responses) => {
            const forecastCalculations = [];
            const localForecastDatas = [];
            responses.forEach((resp) => {
                if (resp?.error) {
                    console.log('error: ', resp.error);
                } else {
                    if (resp.propertyId) {
                        forecastCalculations.push({
                            propertyId: resp.propertyId,
                            forecastCalculation: resp.result
                        });
                    } else {
                        if (resp.length > 0) {
                            if (localForecastDatas.length === 0) {
                                localForecastDatas.push(resp[0]);
                            }
                            if (localForecastDatas.length > 0) {
                                if (localForecastDatas.find((item) => item._id === resp[0]._id))
                                    return;
                                localForecastDatas.push(resp[0]);
                            }
                        }
                    }
                }
            });
            forecastCalculations.length > 0 &&
                setPropertiesForecastCalculation(forecastCalculations);
            localForecastDatas.length > 0 && setForecastDatas(localForecastDatas);
            setDataFetched(true);
        });
    }, [currentProperties]);

    // perform calculations
    useEffect(() => {
        if (!dataFetched || !currentTaxsubject) return;
        if (!forecastDatas || !propertiesForecastCalculation) {
            setExpenseIncomeBeforeTaxData([undefined, '-']);
            setFullExpectedRentData([undefined, '-']);
            setNetProfitData([undefined, '-']);
            setBrutoRentalYieldData([undefined, '-']);
            setRemainigLoanData([undefined, '-']);
            setRentPerSquareMeterData([undefined, '-']);
            setPlanedNetRentalYieldData([undefined, '-']);
            setNumberOfPropertiesData([undefined, '-']);
            return;
        }

        let localRemainingData = null;
        let localFullExpectedMontlyRentData = null;
        let localExpenseIncomeBeforeTaxData = null;
        let localRentPerSquareMeterData = null;
        let localNumberOfPropertiesData = null;
        let localNetProfitData = null;
        let localBrutoRentalYieldData = null;
        let localPlanedNetRentalYieldData = null;
        let localCostValue = null;
        let localHobbieProperties = null;

        currentProperties.forEach((property) => {
            const forecastData = forecastDatas.find(
                (forecastData) => forecastData._owner === property._id
            );
            if (!forecastData) return;
            const currentYear = getUTCDate().getUTCFullYear();
            const propertyForecastCalculation = propertiesForecastCalculation.find(
                (item) => item.propertyId === property._id
            )?.forecastCalculation[0]?.Prognoserechnung;
            if (!propertyForecastCalculation) return;
            const purchaseDate = getUTCDate(forecastData.forecastsData?.l_purchase_date);
            const purchaseYear = purchaseDate?.getUTCFullYear();
            const share_of_land = property?.propertiesData?.l_share_of_land;
            const purchase_price_kitchen =
                forecastData?.forecastsData?.l_purchase_price_kitchen || 0;
            const yearsSincePurchase = currentYear - purchaseYear;
            const totalPurchasePriceBrutto = +forecastData.forecastsData?.l_purchase_price;

            const purchasePriceNetInclAncillaryPurchaseCosts =
                -propertyForecastCalculation[0].purchase_price_net_incl_anchilliary_purchase_costs;

            //get remaining loan data (Offener Saldo)
            const propertyHistory = propertyForecastCalculation.filter(
                (item) => item.year <= currentYear
            );
            const historyYears = propertyHistory.map((item) => item.year);
            localRemainingData = yearlySumUp(
                localRemainingData,
                propertyHistory.map((item) => -item.remaining_loan_full),
                historyYears
            );

            // number of properties graph
            historyYears.map((year) => {
                if (localNumberOfPropertiesData?.[year]) localNumberOfPropertiesData[year]++;
                else localNumberOfPropertiesData = { ...localNumberOfPropertiesData, [year]: 1 };
            });

            //get full expected monthly rent
            localFullExpectedMontlyRentData = yearlySumUp(
                localFullExpectedMontlyRentData,
                propertyHistory.map((item) => item.l_cash_flow_after_taxes),
                historyYears
            );

            //get expense income before tax

            localExpenseIncomeBeforeTaxData = yearlySumUp(
                localExpenseIncomeBeforeTaxData,
                propertyHistory.map((item) => item.l_expense_income_before_tax),
                historyYears
            );

            //get  brutto rental yield

            localBrutoRentalYieldData = yearlySumUp(
                localBrutoRentalYieldData,
                propertyHistory.map(
                    (item) =>
                        item.l_yearly_return_on_total_cash_inflow_after_tax *
                        (purchasePriceNetInclAncillaryPurchaseCosts - purchase_price_kitchen)
                ),
                historyYears
            );
            localCostValue = yearlySumUp(
                localCostValue,
                historyYears.map(
                    () => purchasePriceNetInclAncillaryPurchaseCosts - purchase_price_kitchen
                ),
                historyYears
            );
            //get net profit
            const proceeds = Array(yearsSincePurchase + 1)
                .fill(0)
                .map((_, i) => {
                    return (
                        +totalPurchasePriceBrutto *
                        (1 + +forecastData.forecastsData?.l_value_development_rate / 100) ** i
                    );
                });
            const vat_reduction = historyYears.map((year, i) =>
                Math.max(
                    0,
                    (propertyHistory[i].vat_before_rent_start / 20) * (20 - (year - purchaseYear))
                )
            );
            const proceeds_after_vat_reduction = historyYears.map(
                (_, i) => proceeds[i] - vat_reduction[i]
            );
            const depreciation_base =
                purchasePriceNetInclAncillaryPurchaseCosts -
                +purchase_price_kitchen -
                ((purchasePriceNetInclAncillaryPurchaseCosts - +purchase_price_kitchen) *
                    share_of_land) /
                    100;
            const purchase_price_building_and_renovation_non_subsidised =
                depreciation_base + +purchase_price_kitchen;
            let sum_accelerated_depreciation = 0;
            let sum_depreciation_building = 0;
            propertyHistory.map((item) => {
                sum_accelerated_depreciation += item.additional_accelerated_depreciation;
            });
            historyYears.map((_, i) => {
                sum_depreciation_building += Math.min(
                    (purchase_price_building_and_renovation_non_subsidised / 67) * i,
                    purchase_price_building_and_renovation_non_subsidised -
                        sum_accelerated_depreciation
                );
            });
            const subsidised_depreciation = 0;
            const repair_costs_depreciation = 0;
            const used_depreciation =
                sum_accelerated_depreciation +
                sum_depreciation_building +
                subsidised_depreciation +
                repair_costs_depreciation;

            const still_available_costs = totalPurchasePriceBrutto - used_depreciation;
            localNetProfitData = yearlySumUp(
                localNetProfitData,
                proceeds_after_vat_reduction.map((item) => item - still_available_costs),
                historyYears
            );

            //get rent per square meter
            localRentPerSquareMeterData = yearlySumUp(
                localRentPerSquareMeterData,
                propertyHistory.map((item) => item.l_property_value),
                historyYears
            );

            // planned net rental yield (Mietrendite)
            let specialRepayments = {};
            forecastData?.forecastsData?.specialRepaymentsPages &&
                forecastData?.forecastsData?.specialRepaymentsPages.map((item) => {
                    let year = getUTCDate(
                        forecastData?.forecastsData?.['l_prepayment_date_' + item.id]
                    ).getUTCFullYear();
                    if (year <= currentYear) {
                        if (specialRepayments?.[year])
                            specialRepayments[year] +=
                                forecastData?.forecastsData?.['l_prepayment_value_' + item.id];
                        else
                            specialRepayments = {
                                ...specialRepayments,
                                [year]: forecastData?.forecastsData?.[
                                    'l_prepayment_value_' + item.id
                                ]
                            };
                    }
                });
            localPlanedNetRentalYieldData = yearlySumUp(
                localPlanedNetRentalYieldData,
                Object.values(specialRepayments).map(
                    (item) => item + forecastData?.forecastsData?.l_loan_down_payment
                ).length > 0
                    ? Object.values(specialRepayments).map(
                          (item) => +item + +forecastData?.forecastsData?.l_loan_down_payment
                      )
                    : [
                          ...historyYears
                              .filter((_, i) => i < historyYears.length - 1)
                              .map(() => 0),
                          +(forecastData?.forecastsData?.l_loan_down_payment || 0)
                      ],
                historyYears
            );

            const yearsAfterRenting = propertyForecastCalculation[0].yearsUntilProfit;
            if (
                yearsAfterRenting >
                    (forecastData?.forecastsData?.l_purchase_date &&
                    new Date(forecastData?.forecastsData?.l_purchase_date) > new Date('31-12-2023')
                        ? property?.propertiesData?.l_type_of_rental ===
                          constants.RENTAL_TYPES.LARGE
                            ? 30
                            : 25
                        : property?.propertiesData?.l_type_of_rental ===
                            constants.RENTAL_TYPES.LARGE
                          ? 25
                          : 20) ||
                yearsAfterRenting === undefined
            ) {
                const hobbieProperty = {
                    propertyId: property._id,
                    adress: property.propertiesData.l_address_line
                        ? property.propertiesData.l_address_line
                        : property.propertiesData.l_street +
                          ' ' +
                          property.propertiesData?.l_house_number +
                          `${
                              property.propertiesData?.l_stair
                                  ? `/${property.propertiesData.l_stair}`
                                  : ''
                          }` +
                          `${
                              property.propertiesData?.l_door_number
                                  ? `/${property.propertiesData.l_door_number}`
                                  : ''
                          }`
                };
                if (localHobbieProperties) {
                    localHobbieProperties.push(hobbieProperty);
                } else {
                    localHobbieProperties = [hobbieProperty];
                }
            }
        });
        localNumberOfPropertiesData && Object.keys(localNumberOfPropertiesData).length > 0
            ? setNumberOfPropertiesData([
                  {
                      labels: Object.keys(localNumberOfPropertiesData),
                      datasets: [
                          {
                              data: Object.values(localNumberOfPropertiesData),
                              backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                              borderSkipped: true,
                              barThickness: 5
                          }
                      ]
                  },
                  Object.values(localNumberOfPropertiesData)[
                      Object.values(localNumberOfPropertiesData).length - 1
                  ]
              ])
            : setRemainigLoanData([undefined, '-']);
        localRemainingData && Object.keys(localRemainingData).length > 0
            ? setRemainigLoanData([
                  {
                      labels: Object.keys(localRemainingData),
                      datasets: [
                          {
                              data: Object.values(localRemainingData),
                              backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                              borderSkipped: true,
                              barThickness: 5
                          }
                      ]
                  },
                  Object.values(localRemainingData)[Object.values(localRemainingData).length - 1]
              ])
            : setRemainigLoanData([undefined, '-']);
        localExpenseIncomeBeforeTaxData && Object.keys(localExpenseIncomeBeforeTaxData).length > 0
            ? setExpenseIncomeBeforeTaxData([
                  {
                      labels: Object.keys(localExpenseIncomeBeforeTaxData),
                      datasets: [
                          {
                              data: Object.values(localExpenseIncomeBeforeTaxData),
                              backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                              borderSkipped: true,
                              barThickness: 5
                          }
                      ]
                  },
                  Object.values(localExpenseIncomeBeforeTaxData)[
                      Object.values(localExpenseIncomeBeforeTaxData).length - 1
                  ]
              ])
            : setExpenseIncomeBeforeTaxData([undefined, '-']);
        localFullExpectedMontlyRentData && Object.keys(localFullExpectedMontlyRentData).length > 0
            ? setFullExpectedRentData([
                  {
                      labels: Object.keys(localFullExpectedMontlyRentData),
                      datasets: [
                          {
                              data: Object.values(localFullExpectedMontlyRentData),
                              backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                              borderSkipped: true,
                              barThickness: 5
                          }
                      ]
                  },
                  Object.values(localFullExpectedMontlyRentData)[
                      Object.values(localFullExpectedMontlyRentData).length - 1
                  ]
              ])
            : setFullExpectedRentData([undefined, '-']);
        localNetProfitData && Object.keys(localNetProfitData).length > 0
            ? setNetProfitData([
                  {
                      labels: Object.keys(localNetProfitData),
                      datasets: [
                          {
                              data: Object.values(localNetProfitData),
                              backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                              borderSkipped: true,
                              barThickness: 5
                          }
                      ]
                  },
                  Object.values(localNetProfitData)[Object.values(localNetProfitData).length - 1]
              ])
            : setFullExpectedRentData([undefined, '-']);
        localBrutoRentalYieldData && Object.keys(localBrutoRentalYieldData).length > 0
            ? setBrutoRentalYieldData([
                  {
                      labels: Object.keys(localBrutoRentalYieldData),
                      datasets: [
                          {
                              data: Object.values(localBrutoRentalYieldData).map(
                                  (item, i) => item / Object.values(localCostValue)[i]
                              ),
                              backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                              borderSkipped: true,
                              barThickness: 5
                          }
                      ]
                  },
                  Object.values(localBrutoRentalYieldData)[
                      Object.values(localBrutoRentalYieldData).length - 1
                  ] / Object.values(localCostValue)[Object.values(localCostValue).length - 1]
              ])
            : setBrutoRentalYieldData([undefined, '-']);
        localRentPerSquareMeterData && Object.keys(localRentPerSquareMeterData).length > 0
            ? setRentPerSquareMeterData([
                  {
                      labels: Object.keys(localRentPerSquareMeterData),
                      datasets: [
                          {
                              data: Object.values(localRentPerSquareMeterData),
                              backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                              borderSkipped: true,
                              barThickness: 5
                          }
                      ]
                  },
                  Object.values(localRentPerSquareMeterData)[
                      Object.values(localRentPerSquareMeterData).length - 1
                  ]
              ])
            : setRentPerSquareMeterData([undefined, '-']);
        localPlanedNetRentalYieldData && Object.keys(localPlanedNetRentalYieldData).length > 0
            ? setPlanedNetRentalYieldData([
                  {
                      labels: Object.keys(localPlanedNetRentalYieldData),
                      datasets: [
                          {
                              data: Object.values(localPlanedNetRentalYieldData),
                              backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                              borderSkipped: true,
                              barThickness: 5
                          }
                      ]
                  },
                  Object.values(localPlanedNetRentalYieldData)[
                      Object.values(localPlanedNetRentalYieldData).length - 1
                  ]
              ])
            : setPlanedNetRentalYieldData([undefined, '-']);
        localHobbieProperties && setHobbieProperties(localHobbieProperties);
    }, [dataFetched, forecastDatas]);

    const goToForecastPage = (propertyId) => {
        const currentPropertyIndex = currentProperties.findIndex((p) => p._id === propertyId);
        if (currentPropertyIndex === -1) return;
        dispatch(setOpenedPropertyForecast(currentPropertyIndex.toString()));
        navigate(constants.ROUTES.FORECAST_CALCULATION.HOME);
    };

    const goToInvites = () => {
        setInvitationModalOpen(true);
    };

    return (
        <ContentPageBase
            className="entity-dashboard-page"
            bgIcon={taxsubjectBg}
            altBgIcon={'taxsubject-bg'}>
            <Stack justifyContent="space-between" spacing={10} alignItems="flex-start" wrap={true}>
                <Stack.Item>
                    {taxSubjectName ? (
                        <h2>{taxSubjectName}</h2>
                    ) : (
                        <Placeholder.Paragraph rows={1} active />
                    )}
                </Stack.Item>
                <Stack.Item>
                    {taxSubjectsDropdownOptions && taxsubjects && currentTaxsubject && (
                        <SelectPicker
                            className="tax-subject-dropdown"
                            cleanable={false}
                            size="md"
                            data={taxSubjectsDropdownOptions}
                            renderValue={(value, item, selectedElement) => (
                                <>
                                    {<FontAwesomeIcon className="rs-icon" icon={faUser} />}
                                    {selectedElement}
                                </>
                            )}
                            value={currentTaxsubject ? currentTaxsubject._id : null}
                            searchable={false}
                            placement="auto"
                            onChange={(value) => {
                                const selectedTaxSubject = taxsubjects.find(
                                    (taxSubject) => taxSubject._id === value
                                );
                                dispatch(setCurrentTaxsubject(selectedTaxSubject));
                                analytics.trackChangeTaxSubject(selectedTaxSubject?._id);
                            }}
                        />
                    )}
                </Stack.Item>
            </Stack>
            {currentTaxsubject && (
                <div className="dashboard-section">
                    <ClickInput
                        label={t('tax_subject_dashboard.h_kpis')}
                        labelClass="section-label"
                        actionClass="align-right"
                    />
                    <Stack wrap justifyContent="center" className="kpis" spacing={20}>
                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_1')}
                            removeDynamicColor
                            mainValue={
                                numberOfPropertiesData?.[1] &&
                                numberOfPropertiesData?.[1] !== '-' &&
                                numberOfPropertiesData?.[1].toFixed(2)
                            }
                            graphData={numberOfPropertiesData?.[0]}
                            info={numberOfPropertiesData?.[0] && propertyTypesText}
                            tooltip={t('tax_subject_dashboard.i_kpi_1')}
                        />
                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_2')}
                            symbol="€"
                            mainValue={
                                remainigLoanData?.[1] &&
                                remainigLoanData?.[1] !== '-' &&
                                remainigLoanData?.[1].toFixed(2)
                            }
                            graphData={remainigLoanData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_2_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_2')}
                        />
                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_3')}
                            symbol="€"
                            mainValue={
                                fullExpectedRentData?.[1] &&
                                fullExpectedRentData?.[1] !== '-' &&
                                fullExpectedRentData?.[1].toFixed(2)
                            }
                            graphData={fullExpectedRentData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_3_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_3')}
                        />

                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_4')}
                            symbol="%"
                            mainValue={
                                brutoRentalYieldData?.[1] &&
                                brutoRentalYieldData?.[1] !== '-' &&
                                brutoRentalYieldData?.[1].toFixed(2)
                            }
                            graphData={brutoRentalYieldData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_4_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_4')}
                        />

                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_5')}
                            symbol="€"
                            mainValue={
                                expenseIncomeBeforeTaxData?.[1] &&
                                expenseIncomeBeforeTaxData?.[1] !== '-' &&
                                expenseIncomeBeforeTaxData?.[1].toFixed(2)
                            }
                            graphData={expenseIncomeBeforeTaxData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_5_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_5')}
                        />

                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_6')}
                            symbol="€"
                            mainValue={
                                netProfitData?.[1] &&
                                netProfitData?.[1] !== '-' &&
                                netProfitData?.[1].toFixed(2)
                            }
                            graphData={netProfitData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_6_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_6')}
                        />

                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_7')}
                            symbol="€"
                            mainValue={
                                rentPerSquareMeterData?.[1] &&
                                rentPerSquareMeterData?.[1] !== '-' &&
                                rentPerSquareMeterData?.[1].toFixed(2)
                            }
                            graphData={rentPerSquareMeterData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_7_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_7')}
                        />

                        <KpiItem
                            label={t('tax_subject_dashboard.kpi_8')}
                            symbol="€"
                            mainValue={
                                (planedNetRentalYieldData?.[1] &&
                                    planedNetRentalYieldData?.[1] !== '-' &&
                                    planedNetRentalYieldData?.[1].toFixed(2)) ||
                                '0'
                            }
                            graphData={planedNetRentalYieldData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_8_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_8')}
                        />
                    </Stack>
                </div>
            )}

            <div className="dashboard-section">
                <h3 className="section-label">{t('tax_subject_dashboard.h_actions')}</h3>
                {currentProperties && currentProperties.length === 0 && (
                    <Panel className="action-panel">
                        <ClickInput
                            label={t('tax_subject_dashboard.l_add_first_property_message')}
                            actionName={t('tax_subject_dashboard.l_create_now')}
                            actionClass="align-right"
                            onClick={() => {
                                analytics.trackClick(
                                    'create_first_property',
                                    analyticsConstants.CATEGORIES.PROPERTIES
                                );
                                navigate(constants.ROUTES.PROPERTIES.CREATE);
                            }}
                        />
                    </Panel>
                )}

                {hobbieProperties &&
                    hobbieProperties.length > 0 &&
                    hobbieProperties.map((property, key) => (
                        <Panel className="action-panel" key={key}>
                            <ClickInput
                                label={t(
                                    'tax_subject_dashboard.l_hobbyism_warning_for_flat'
                                ).replace('{{property}}', property.adress)}
                                actionName={t('tax_subject_dashboard.l_edit_forecast')}
                                actionClass="align-right"
                                onClick={() => {
                                    analytics.trackClick(
                                        'go_to_forecast_page',
                                        analyticsConstants.CATEGORIES.FORECASTS
                                    );
                                    goToForecastPage(property.propertyId);
                                }}
                            />
                        </Panel>
                    ))}
                {currentTaxsubject && (
                    <Panel className="action-panel">
                        <ClickInput
                            label={t('tax_subject_dashboard.l_edit_access_message')}
                            actionName={t('tax_subject_dashboard.l_edit_access')}
                            actionClass="align-right"
                            onClick={() => {
                                analytics.trackClick(
                                    'open_invites',
                                    analyticsConstants.CATEGORIES.TAX_SUBJECTS
                                );
                                goToInvites();
                            }}
                        />
                    </Panel>
                )}
            </div>
            <CreateInvitationModal
                opened={invitationModalOpen}
                setOpened={setInvitationModalOpen}
                taxsubjectID={currentTaxsubject?._id}
            />
            <TaxSubjectDashboardSection
                taxsubject={currentTaxsubject}
                onEditTaxsubject={() => {
                    editTaxSubject(currentTaxsubject?._id);
                    analytics.trackClick(
                        'edit_taxsubject',
                        analyticsConstants.CATEGORIES.TAX_SUBJECTS
                    );
                }}
            />
            <PromotionCard
                body={t('dashboard.l_promo1')}
                buttonText={t('dashboard.l_cta_text_promo1')}
                onButtonClick={() => window.open(t('dashboard.l_cta_promo1'), '_blank')}
                title={t('dashboard.h_promo1')}
                icon={<FontAwesomeIcon icon={faCalendarDays} />}
            />
            <PromotionCard
                body={t('dashboard.l_promo2')}
                buttonText={t('dashboard.l_cta_text_promo2')}
                onButtonClick={() => window.open(t('dashboard.l_cta_promo2'), '_blank')}
                title={t('dashboard.h_promo2')}
            />
        </ContentPageBase>
    );
};

export default TaxSubjectDashboard;
