import {
    ObjectType,
    StringType,
    NumberType,
    MixedType,
    ArrayType,
    DateType,
    BooleanType
} from 'schema-typed';
import getUTCDate from './getUTCDate';
// https://github.com/rsuite/schema-typed#schema-typed
// here are the validation types

export const getValidationSchemas = (t) => {
    return {
        login: ObjectType().shape({
            email: StringType()
                .isEmail(t('authentication.e_email'))
                .isRequired(t('global.e_required')),
            password: StringType()
                .minLength(8, t('authentication.e_password_length'))
                .isRequired(t('global.e_required'))
        }),
        signup: ObjectType().shape({
            email: StringType()
                .isEmail(t('authentication.e_email'))
                .isRequired(t('global.e_required')),
            password: StringType()
                .minLength(8, t('authentication.e_password_length'))
                .isRequired(t('global.e_required'))
        }),
        forgottenPassw: ObjectType().shape({
            email: StringType()
                .isEmail(t('authentication.e_email'))
                .isRequired(t('global.e_required'))
        }),
        resetPassw: ObjectType().shape({
            password: StringType()
                .minLength(8, t('authentication.e_password_length'))
                .isRequired(t('global.e_required')),
            repeatPassword: StringType()
                .addRule((value, data) => {
                    if (value !== data.password) {
                        return false;
                    }
                    return true;
                }, t('authentication.e_passwords_do_not_match'))
                .isRequired(t('global.e_required'))
        }),
        onboarding: ObjectType().shape({
            userType: StringType().isRequired(t('global.e_required')),
            interestInfo: ArrayType().minLength(1, t('global.e_required')),
            firstname: StringType().isRequired(t('global.e_required')),
            lastname: StringType().isRequired(t('global.e_required'))
        }),
        profile: ObjectType().shape({
            firstname: StringType().isRequired(t('global.e_required')),
            lastname: StringType().isRequired(t('global.e_required')),
            zipCode: NumberType(t('global.e_number'))
                .isInteger(t('global.e_number'))
                .min(1000, t('global.e_x_digits').replace('{{x}}', 4))
                .max(9999, t('global.e_x_digits').replace('{{x}}', 4))
        }),
        addEditTaxSubject: ObjectType().shape({
            type: StringType().isRequired(t('global.e_required')),
            l_firstname: StringType().isRequired(t('global.e_required')),
            l_lastname: StringType().isRequired(t('global.e_required')),
            l_company: StringType().isRequired(t('global.e_required')),
            l_address_line: StringType(),
            l_zip: NumberType(t('global.e_number'))
                .isInteger(t('global.e_number'))
                .min(1000, t('global.e_x_digits').replace('{{x}}', 4))
                .max(9999, t('global.e_x_digits').replace('{{x}}', 4)),
            l_city: StringType(),
            tax_number: StringType().isRequired(t('global.e_required')),
            tax_progression: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        }),
        basicPropertyInformation: ObjectType().shape({
            _owner_id: StringType().isRequired(t('global.e_required')),
            l_property_type: StringType().isRequired(t('global.e_required')),
            l_address_line: StringType().isRequired(t('global.e_required')),
            l_street: StringType().isRequired(t('global.e_required')),
            l_house_number: StringType().isRequired(t('global.e_required')),
            l_stair: StringType(),
            l_door_number: StringType(),
            l_zip: NumberType(t('global.e_number'))
                .isInteger(t('global.e_number'))
                .min(1000, t('global.e_x_digits').replace('{{x}}', 4))
                .max(9999, t('global.e_x_digits').replace('{{x}}', 4)),
            l_city: StringType().isRequired(t('global.e_required'))
        }),
        detailedInormation: ObjectType().shape({
            l_construction_year: NumberType(t('global.e_number'))
                .pattern(/^(1|2)[0-9][0-9][0-9]$/, t('global.e_year'))
                .isRequired(t('global.e_required')),
            last_renovation_year: NumberType(t('global.e_number'))
                .pattern(/^(1|2)[0-9][0-9][0-9]$/, t('global.e_year'))
                .isRequired(t('global.e_required')),
            l_area_of_living_space: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_usable_area: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_number_of_garages: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_share_of_land: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_floor_number: NumberType(t('global.e_number')).min(0, t('global.e_positive_number')),
            l_type_of_rental: StringType().isRequired(t('global.e_required'))
        }),
        areaInformation: ObjectType().shape({
            l_area_of_living_space: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loggia: NumberType(t('global.e_number')).min(0, t('global.e_positive_number')),
            l_balcony: NumberType(t('global.e_number')).min(0, t('global.e_positive_number')),
            l_terrace: NumberType(t('global.e_number')).min(0, t('global.e_positive_number')),
            l_garden: NumberType(t('global.e_number')).min(0, t('global.e_positive_number')),
            l_basement: NumberType(t('global.e_number')).min(0, t('global.e_positive_number'))
        }),
        ancillaryPurchaseCost: ObjectType().shape({
            l_trust_and_contract_establishment_fee: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_notary_cash_expenses: NumberType(t('global.e_number')).min(
                0,
                t('global.e_positive_number')
            ),
            l_land_register_registration_fee: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_land_register_registration_fee_percent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_real_estate_transfer_tax: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_real_estate_transfer_tax_percent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_real_estate_broker_fee: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_real_estate_broker_fee_percent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_costs_advertising_misc: NumberType(t('global.e_number')).min(
                0,
                t('global.e_positive_number')
            )
        }),
        financing: ObjectType().shape({
            l_loan_start_date: DateType()
                .isRequired(t('global.e_required'))
                .addRule((value, data) => {
                    if (value && data.l_purchase_date)
                        return (
                            getUTCDate(value).getTime() >=
                            getUTCDate(data.l_purchase_date).getTime()
                        );
                    else return false;
                }, t('create_forecast.e_loan_start_date_before_purchase')),
            l_loan_term: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_type: MixedType().isRequired(t('global.e_required')),
            l_loan_duration_fixed_rate: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_fixed_rate: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_loan_adjustable_rate: StringType().isRequired(t('global.e_required')),
            l_total_loan_value: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
                .addRule((value, data) => {
                    const totalPriceNet =
                        data?.l_purchase_price * (data?.l_purchase_type === 1 ? 10 / 12 : 1);
                    let purchaseAncilliaryCost =
                        +data?.l_real_estate_transfer_tax +
                        +data?.l_land_register_registration_fee +
                        +data?.l_trust_and_contract_establishment_fee +
                        +(data?.l_notary_cash_expenses ? data.l_notary_cash_expenses : 0) +
                        +(data?.l_costs_advertising_misc ? data.l_costs_advertising_misc : 0) +
                        data?.l_real_estate_broker_fee +
                        +(data?.l_ancillary_financing_costs_misc
                            ? +data?.l_ancillary_financing_costs_misc
                            : 0);
                    data?.otherAncillaryCostPages &&
                        data.otherAncillaryCostPages.map(
                            (page) =>
                                (purchaseAncilliaryCost +=
                                    +data['l_anc_cost_item_value_' + page.id])
                        );
                    const purchasePriceNetInclAnchilliaryPurchaseCosts =
                        totalPriceNet + purchaseAncilliaryCost;
                    return (
                        value >= (purchasePriceNetInclAnchilliaryPurchaseCosts * 0.98).toFixed(2)
                    );
                }, t('create_forecast.e_total_loan_value_too_low')),
            l_loan_principal: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_down_payment: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_down_payment_percent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_costs_land_register_entry_lien: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_costs_land_register_entry_lien_percent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_bank_processing_fee: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_bank_processing_fee_percent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_ancillary_financing_costs_misc: NumberType(t('global.e_number')).min(
                0,
                t('global.e_positive_number')
            )
        }),
        purchase: ObjectType().shape({
            l_purchase_date: MixedType().isRequired(t('global.e_required')),
            l_purchase_price: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_purchase_type: MixedType().isRequired(t('global.e_required'))
        }),
        revenues: ObjectType().shape({
            l_expected_monthly_rent: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_rent_start_date: DateType()
                .isRequired(t('global.e_required'))
                .addRule((value, data) => {
                    if (value && data.l_purchase_date)
                        return (
                            getUTCDate(value).getTime() >=
                            getUTCDate(data.l_purchase_date).getTime()
                        );
                    else return false;
                }, t('create_forecast.e_rent_start_date_before_purchase')),
            l_expected_monthly_rent_kitchen: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_expected_monthly_rent_garage: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_expected_monthly_rent_misc: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_rent_increase: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_rent_increase_start_date: NumberType(t('global.e_number')).isRequired(
                t('global.e_required')
            ),

            l_costs_initial_rental: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_operating_costs_20: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_operating_costs_10: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        }),
        expenses: ObjectType().shape({
            l_costs_housing_management: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_costs_tax_advisor: StringType().isRequired(t('global.e_required')),
            l_repair_costs_method: MixedType().isRequired(t('global.e_required')),
            l_maintenance_reserves_percent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_repair_costs_of_rent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_vacancy_rate: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_reserves: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_repair_costs_of_rent_rate: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_bank_account_fees: NumberType(t('global.e_number')).min(
                0,
                t('global.e_positive_number')
            ),
            l_expenses_paragraph_28_3: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_immediately_deductables: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        }),
        addNewCost: ObjectType().shape({
            l_property_type: StringType().isRequired(t('global.e_required')),
            l_depreciation: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_purchase_price: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        }),
        sondertilgung: ObjectType().shape({
            l_prepayment_date: MixedType().isRequired(t('global.e_required')),
            l_prepayment_value: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_prepayment_origin: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        }),
        taxesAndValueDevelopment: ObjectType().shape({
            l_value_development_rate: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_inflation_rate: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_depreciation: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_depreciation_kitchen: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_depreciation_garage: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_depreciation_misc: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required'))
        }),
        specialRepayments: ObjectType().shape({
            l_link_to_documents: StringType().pattern(
                /^(http|https):\/\/[^ "]+$/,
                'Please enter a valid link'
            )
        }),
        revenue: ObjectType().shape({
            l_yearly_rent: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_monthly_target_rent: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_monthly_rent_historical: NumberType(t('global.e_number')).min(
                0,
                t('global.e_positive_number')
            ),
            l_rent_increase_historical: NumberType(t('global.e_number')).range(
                0,
                100,
                t('global.e_percentage')
            ),
            l_vacancy_rate_historical: NumberType(t('global.e_number')).range(
                0,
                100,
                t('global.e_percentage')
            )
        }),
        expenses_1: ObjectType().shape({
            l_costs_housing_management: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_costs_tax_advisor: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_costs_housing_management_f: NumberType(t('global.e_number')).min(
                0,
                t('global.e_positive_number')
            ),
            l_costs_tax_advisor_f: NumberType(t('global.e_number')).min(
                0,
                t('global.e_positive_number')
            )
        }),
        expenses_2: ObjectType().shape({
            l_operating_costs_20: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_operating_costs_10: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_reserves: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        }),
        expenses_3: ObjectType().shape({
            l_balance_beginning_of_year: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_balance_end_of_year: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_interest_expense: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_special_repayments: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_bank_account_fees: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_fixed_rate: NumberType(t('global.e_number')).range(
                0,
                100,
                t('global.e_percentage')
            ),
            l_loan_duration_fixed_rate: NumberType(t('global.e_number')).min(
                0,
                t('global.e_positive_number')
            ),
            l_loan_adjustable_rate: NumberType(t('global.e_number')).range(
                0,
                100,
                t('global.e_percentage')
            )
        }),
        expenses_4: ObjectType().shape({
            l_expenses_paragraph_28_2: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_expenses_paragraph_28_3: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_maintenance_expenses_period: NumberType(t('global.e_number'))
                .range(10, 15, t('global.e_between_x_y').replace('{{x}}', 10).replace('{{y}}', 15))
                .isRequired(t('global.e_required')),
            l_expenses_paragraph_28_4: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_immediately_deductables: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        }),
        scenarioDataPage: ObjectType().shape({
            loss_of_rent_risk: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            monthly_net_rent: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            flexible_interest_rate: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            increase_rate_expenses: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            increase_rate_income: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            increase_in_value: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            scenario_name: StringType().isRequired(t('global.e_required'))
        }),
        adminEditUser: ObjectType().shape({
            l_firstname: StringType(t('global.e_number')).isRequired(t('global.e_required')),
            l_lastname: StringType(t('global.e_number')).isRequired(t('global.e_required')),
            l_email: StringType()
                .isEmail(t('authentication.e_email'))
                .isRequired(t('global.e_required')),
            l_user_type: StringType().isRequired(t('global.e_required')),
            l_status: BooleanType().isRequired(t('global.e_required')),
            l_plan: StringType().isRequired(t('global.e_required')),
            l_costs: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_property_limit: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_tax_subject_limit: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        }),
        forecastQuickCheck: ObjectType().shape({
            l_renovation_costs: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_depreciation: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_term: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_fixed_rate: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_purchase_price: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_down_payment: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_loan_down_payment_percent: NumberType(t('global.e_number'))
                .range(0, 100, t('global.e_percentage'))
                .isRequired(t('global.e_required')),
            l_expected_monthly_rent: NumberType(t('global.e_number'))
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        })
    };
};
