import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentPageBase from '../../components/ContentPageBase';
import NavigationFooter from '../../components/NavigationFooter';
import StatusNavBar from '../../components/StatusNavBar';
import Revenue from '../historicalDataPages/Revenue';
import { Button, Divider, Form, Stack } from 'rsuite';
import { useTranslation } from 'react-i18next';
import Expenses_1 from '../historicalDataPages/Expenses_1';
import Expenses_2 from '../historicalDataPages/Expenses_2';
import Expenses_3 from '../historicalDataPages/Expenses_3';
import Expenses_4 from '../historicalDataPages/Expenses_4';
import showValidationErrors from '../../utils/showValidationErrors';
import {
    setHistoricalData,
    closeHelpDrawer,
    setRequestFinished,
    setRequestMessage,
    openHelpDrawer
} from '../../reducers/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import ResponsiveStatusNavBar from '../../components/ResponsiveStatusNavBar';

const HistoricalData = ({ year, forecastTable }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openedPropertyForecast = useSelector((state) => state.user.openedPropertyForecast);
    const properties = useSelector((state) => state.user.properties);
    const forecastData = useSelector((state) => state.user.forecastData);
    const historicalData = useSelector((state) => state.user.historicalData);
    const requestFinished = useSelector((state) => state.user.requestFinished);
    const requestMessage = useSelector((state) => state.user.requestMessage);

    const localForecastData = useMemo(() => {
        let returnedObject = {
            ...forecastTable,
            l_costs_housing_management: forecastData?.forecastsData?.l_costs_housing_management,
            l_costs_tax_advisor: forecastData?.forecastsData?.l_costs_tax_advisor,
            l_vacancy_rate: forecastData?.forecastsData?.l_vacancy_rate,
            l_financing_via_loan:
                forecastData?.forecastsData?.l_financing_via_loan !== undefined
                    ? forecastData?.forecastsData?.l_financing_via_loan
                    : true,
            l_rent_increase: forecastData?.forecastsData?.l_rent_increase,
            l_ancillary_financing_costs_misc:
                +forecastData?.forecastsData?.l_ancillary_financing_costs_misc,
            l_operating_costs_20: +forecastData?.forecastsData?.l_operating_costs_20,
            l_operating_costs_10: +forecastData?.forecastsData?.l_operating_costs_10,
            l_reserves: +forecastData?.forecastsData?.l_reserves
        };
        return returnedObject;
    }, [forecastData, forecastTable]);

    const pageInfoText = useMemo(
        () => ({
            title: t('create_historic_forecast_data.h_historic_forecast_data').replace(
                '{{year}}',
                year
            ),
            message: t('create_historic_forecast_data.i_historic_forecast_data')
        }),
        [year]
    );

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.HISTORICAL_DATA);
        dispatch(openHelpDrawer(pageInfoText));
        return () => {
            dispatch(closeHelpDrawer());
        };
    }, []);

    useEffect(() => {
        if (requestFinished && requestMessage?.type !== 'error') navigate(-1);
    }, [requestFinished]);

    const [active, setActive] = useState(1);
    const [data, setData] = useState(historicalData?.[year]);
    const [otherCostPages, setOtherCostPages] = useState(
        historicalData?.[year]?.otherCostPages ? historicalData?.[year]?.otherCostPages : []
    );

    useEffect(() => {
        if (data?.l_balance_beginning_of_year || data?.l_balance_end_of_year)
            setData({
                ...data,
                l_repayment:
                    (data?.l_balance_beginning_of_year || 0) - (data?.l_balance_end_of_year || 0)
            });
    }, [data?.l_balance_beginning_of_year, data?.l_balance_end_of_year]);

    useEffect(() => {
        let l_expected_monthly_rent = data?.l_expected_monthly_rent
            ? data.l_expected_monthly_rent
            : localForecastData.yearly_rent / localForecastData.num_rent_active || 0;
        let l_expected_monthly_rent_kitchen =
            data?.l_expected_monthly_rent_kitchen && data?.l_expected_monthly_rent_kitchen !== ''
                ? data.l_expected_monthly_rent_kitchen
                : localForecastData.yearly_rent_kitchen / localForecastData.num_rent_active || 0;
        let l_expected_monthly_rent_garage =
            data?.l_expected_monthly_rent_garage && data.l_expected_monthly_rent_garage !== ''
                ? data.l_expected_monthly_rent_garage
                : localForecastData.yearly_rent_garage / localForecastData.num_rent_active || 0;
        let l_expected_monthly_rent_misc =
            data?.l_expected_monthly_rent_misc && data.l_expected_monthly_rent_misc !== ''
                ? data.l_expected_monthly_rent_misc
                : localForecastData.yearly_rent_misc / localForecastData.num_rent_active || 0;
        let l_rent_increase = data?.l_rent_increase
            ? data.l_rent_increase
            : localForecastData.l_rent_increase;
        let l_vacancy_rate = data?.l_vacancy_rate
            ? data.l_vacancy_rate
            : forecastData.forecastsData.l_vacancy_rate;
        let l_costs_housing_management_f = data?.l_costs_housing_management_f
            ? data.l_costs_housing_management_f
            : localForecastData.l_costs_housing_management;
        let l_costs_tax_advisor_f = data?.l_costs_tax_advisor_f
            ? data.l_costs_tax_advisor_f
            : localForecastData.l_costs_tax_advisor;
        let l_loan_fixed_rate = data?.l_loan_fixed_rate
            ? data.l_loan_fixed_rate
            : forecastData.forecastsData.l_loan_fixed_rate;
        let l_loan_duration_fixed_rate = data?.l_loan_duration_fixed_rate
            ? data.l_loan_duration_fixed_rate
            : forecastData.forecastsData.l_loan_duration_fixed_rate;
        let l_loan_adjustable_rate = data?.l_loan_adjustable_rate
            ? data.l_loan_adjustable_rate
            : forecastData.forecastsData.l_loan_adjustable_rate;
        let l_expenses_paragraph_28_2 = data?.l_expenses_paragraph_28_2
            ? data?.l_expenses_paragraph_28_2
            : 0;
        let l_expenses_paragraph_28_3 = data?.l_expenses_paragraph_28_3
            ? data?.l_expenses_paragraph_28_3
            : 0;
        let l_expenses_paragraph_28_4 = data?.l_expenses_paragraph_28_4
            ? data?.l_expenses_paragraph_28_4
            : 0;
        let l_immediately_deductables = data?.l_immediately_deductables
            ? data?.l_immediately_deductables
            : 0;
        let l_balance_beginning_of_year =
            data?.l_balance_beginning_of_year ||
            (historicalData?.[year - 1] && historicalData[year - 1]?.l_balance_end_of_year) ||
            localForecastData?.remaining_loan_full;
        let l_monthly_target_rent = data?.l_monthly_target_rent
            ? data?.l_monthly_target_rent
            : localForecastData.num_rent_active !== 0
              ? ((localForecastData.yearly_rent || 0) +
                    (localForecastData?.yearly_rent_kitchen || 0) +
                    (localForecastData?.yearly_rent_garage || 0) +
                    (localForecastData?.yearly_rent_misc || 0)) /
                localForecastData.num_rent_active
              : 0;
        setData({
            ...data,
            l_costs_housing_management_f,
            l_expected_monthly_rent_kitchen,
            l_expected_monthly_rent_garage,
            l_expected_monthly_rent_misc,
            l_costs_tax_advisor_f,
            l_vacancy_rate,
            l_rent_increase,
            l_expected_monthly_rent,
            l_loan_fixed_rate,
            l_loan_duration_fixed_rate,
            l_loan_adjustable_rate,
            l_expenses_paragraph_28_2,
            l_expenses_paragraph_28_3,
            l_expenses_paragraph_28_4,
            l_immediately_deductables,
            l_balance_beginning_of_year,
            l_monthly_target_rent
        });
    }, [forecastData, localForecastData]);

    const addPage = () => {
        setOtherCostPages([...otherCostPages, { id: 'id' + new Date().getTime() }]);
    };

    const [navbar, setNavbar] = useState([
        { eventKey: 1, text: t('create_historic_forecast_data.n_earnings'), hasError: false },
        { eventKey: 2, text: t('create_historic_forecast_data.n_expenses1'), hasError: false },
        { eventKey: 3, text: t('create_historic_forecast_data.n_expenses2'), hasError: false },
        { eventKey: 4, text: t('create_historic_forecast_data.n_expenses3'), hasError: false },
        { eventKey: 5, text: t('create_historic_forecast_data.n_expenses4'), hasError: false }
    ]);

    //set the first view of the form to be false for all pages
    //after being viewed once, it will be set to true and the form will be checked for errors
    const [firstView, setFirstView] = useState(Array(navbar.length).fill(!data));

    //check the form for errors on current page
    useEffect(() => {
        if (!formRef.current) return;
        if (firstView[active - 1]) {
            setFirstView(firstView.map((item, index) => (index === active - 1 ? false : item)));
            return;
        }
        showValidationErrors(formRef.current.root);
        formRef.current.check();
    }, [active]);

    const stepper = async (page) => {
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        let navbarHasErrors = navbar.slice(0, -1).filter((item) => item.hasError).length > 0;
        dispatch(closeHelpDrawer());
        let operation = page ? page : active + 1;
        setNavbar(
            navbar.map((item, index) =>
                index === active - 1 ? { ...item, hasError: hasError } : item
            )
        );
        if (page)
            analytics.trackSelectNavItem(page, analyticsConstants.CATEGORIES.PROPERTIES, hasError);
        else {
            const analyticsLabel = data ? 'edit_historical_data' : 'create_historical_data';
            analytics.trackClick(
                analyticsLabel,
                analyticsConstants.CATEGORIES.FORECASTS,
                active,
                hasError
            );
        }

        if (operation === navbar.length + 1) {
            if (hasError || navbarHasErrors || firstView.includes(true)) {
                if (firstView.includes(true)) {
                    let newNavbar = [...navbar];
                    firstView.forEach((item, itemIndex) => {
                        if (item === true) {
                            newNavbar = newNavbar.map((navItem, index) =>
                                index === itemIndex ? { ...navItem, hasError: true } : navItem
                            );
                        }
                    });
                    setNavbar(newNavbar);
                }
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('general.e_generic_form_error')
                    })
                );
                return;
            }
            dispatch(
                setHistoricalData({
                    ...properties[openedPropertyForecast],
                    historicalData: {
                        ...historicalData,
                        [year]: { ...data, otherCostPages: otherCostPages }
                    }
                })
            );
        } else setActive(operation);
    };

    return (
        <ContentPageBase
            className={'historical-data-page'}
            leftItem={
                window.innerWidth > 850 ? (
                    <StatusNavBar
                        appearance="subtle"
                        active={active}
                        onSelect={stepper}
                        titles={navbar}
                    />
                ) : null
            }
            topItem={
                window.innerWidth <= 850 ? (
                    <ResponsiveStatusNavBar
                        appearance="tabs"
                        active={active}
                        onSelect={stepper}
                        titles={navbar}
                    />
                ) : null
            }
            footer={
                <NavigationFooter
                    firstLabel={t('global.cancel')}
                    firstFunction={() => {
                        navigate(-1);
                        const analyticsLabel = data
                            ? 'cancel_edit_historical_data'
                            : 'cancel_create_historical_data';
                        analytics.trackClick(
                            analyticsLabel,
                            analyticsConstants.CATEGORIES.FORECASTS,
                            active
                        );
                    }}
                    secondFunction={() => stepper()}
                    secondLabel={active === navbar.length ? t('global.submit') : t('global.next')}
                    isSubmitButton={
                        active === navbar.length &&
                        navbar.slice(0, -1).filter((item) => item.hasError).length === 0
                    }
                />
            }>
            <Form
                onChange={(value) => setData({ ...data, ...value })}
                ref={formRef}
                formValue={data}
                checkTrigger="none">
                {active === 1 && (
                    <Revenue
                        data={data}
                        setData={setData}
                        year={year}
                        forecastData={localForecastData}
                    />
                )}
                {active === 2 && (
                    <Expenses_1
                        forecastData={localForecastData}
                        data={data}
                        pages={otherCostPages}
                        setPages={setOtherCostPages}>
                        <>
                            <Divider />
                            <Stack justifyContent="flex-end">
                                <Button onClick={() => addPage()}>
                                    + {t('create_forecast.l_add_other_ancillary_purchase_costs')}
                                </Button>
                            </Stack>
                        </>
                    </Expenses_1>
                )}
                {active === 3 && <Expenses_2 forecastData={localForecastData} />}
                {active === 4 && (
                    <Expenses_3
                        forecastData={localForecastData}
                        data={data}
                        historicalData={historicalData}
                        year={year}
                    />
                )}
                {active === 5 && <Expenses_4 data={data} setData={setData} />}
            </Form>
        </ContentPageBase>
    );
};
export default HistoricalData;
