import React, { Fragment } from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { Divider, IconButton, Stack } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import { useTranslation } from 'react-i18next';
import { SchemaModel, StringType, NumberType } from 'schema-typed';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { formatNumber } from '../../components/NumericFormatter';

const Expenses_1 = ({ children, data, forecastData, pages, setPages }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).expenses_1.objectTypeSchemaSpec;

    let models = pages.map(() => {
        return SchemaModel({
            l_cost_item_value: NumberType()
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_cost_item_name: StringType().isRequired(t('global.e_required'))
        });
    });

    const popPage = (selectedItem) => {
        setPages(pages.filter((item) => item !== selectedItem));
    };

    return (
        <>
            <h4>{t('create_historic_forecast_data.h_expenses_1')}</h4>
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_costs_housing_management')}
                type="number"
                autoFocus
                name="l_costs_housing_management"
                symbolIcon="€"
                rule={validationSchemas.l_costs_housing_management}
            />
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_costs_tax_advisor')}
                type="number"
                name="l_costs_tax_advisor"
                symbolIcon="€"
                rule={validationSchemas.l_costs_tax_advisor}
            />
            {pages &&
                pages.map((item, index) => (
                    <Fragment key={item.id}>
                        <Divider />
                        <Stack className="additional-cost-item">
                            <h3 className="page-subtitle">
                                {t('create_forecast.n_ancillary_purchase_costs')}
                            </h3>
                            <IconButton icon={<TrashIcon />} onClick={() => popPage(item)} />
                        </Stack>
                        <LabeledInputWithSymbol
                            label={t('create_forecast.l_cost_item_name')}
                            type="text"
                            infoText={t('create_forecast.i_cost_item_name')}
                            name={'l_cost_item_name_' + item.id}
                            rule={
                                models[index]?.spec?.l_cost_item_name ||
                                models[index]?.$spec?.l_cost_item_name
                            }
                        />
                        <LabeledInputWithSymbol
                            label={t('create_forecast.l_cost_item_value')}
                            type="number"
                            infoText={t('create_forecast.i_cost_item_value')}
                            symbolIcon="€"
                            name={'l_anc_cost_item_value_' + item.id}
                            rule={
                                models[index]?.spec?.l_cost_item_value ||
                                models[index]?.$spec?.l_cost_item_value
                            }
                        />
                    </Fragment>
                ))}
            {children}
            <Divider />
            <h4>{t('create_historic_forecast_data.h_change_forecast_parameters')}</h4>
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_costs_housing_management')}
                type="number"
                name="l_costs_housing_management_f"
                symbolIcon="€"
                rule={validationSchemas.l_costs_housing_management_f}
                defaultValue={
                    data?.l_costs_housing_management_f
                        ? data.l_costs_housing_management_f
                        : forecastData.l_costs_housing_management
                }
                helpText={t('create_historic_forecast_data.l_diff_to_historic_value').replace(
                    '{{value}}',
                    formatNumber(
                        forecastData && data?.l_costs_housing_management_f
                            ? forecastData.l_costs_housing_management -
                                  data.l_costs_housing_management_f
                            : 0
                    )
                )}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_costs_tax_advisor')}
                type="number"
                name="l_costs_tax_advisor_f"
                symbolIcon="€"
                rule={validationSchemas.l_costs_tax_advisor_f}
                defaultValue={
                    data?.l_costs_tax_advisor_f
                        ? data.l_costs_tax_advisor_f
                        : forecastData.l_costs_tax_advisor
                }
                helpText={t('create_historic_forecast_data.l_diff_to_historic_value').replace(
                    '{{value}}',
                    formatNumber(
                        forecastData && data?.l_costs_tax_advisor_f
                            ? forecastData.l_costs_tax_advisor - data.l_costs_tax_advisor_f
                            : 0
                    )
                )}
            />
        </>
    );
};

export default Expenses_1;
